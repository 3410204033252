@import '../../../../scss/theme-bootstrap';

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  66% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

.gnav-secondary-formatter-v1 {
  height: 230px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  z-index: 101;
  position: relative;
  padding-bottom: 45px;
  &:not(.mobile-hidden):not(.pc-hidden) {
    display: flex;
  }
  &.pc-hidden:not(.mobile-hidden) {
    display: flex;
    @include breakpoint($bp--medium-up) {
      display: none;
    }
  }
  &.pc-hidden.mobile-hidden {
    display: none;
  }
  @include breakpoint($bp--large-up) {
    height: 174px;
    padding: 0 20px;
  }
  @include breakpoint($bp--xxlarge-up) {
    height: 248px;
  }
  &.gnav-secondary-formatter-sticky {
    position: fixed;
    margin: 0 auto;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
  }
  .gnav-secondary-formatter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: $color-cta-grey--light;
    margin-bottom: 0;
    overflow: hidden;
    @include breakpoint($bp--large-up) {
      padding: 0 29px;
    }
    @include breakpoint($bp--xxlarge-up) {
      padding: 0 40px;
    }
  }
  .gnav-tout {
    @include breakpoint($bp--large-up) {
      max-width: 130px;
    }
    @include breakpoint($bp--xxlarge-up) {
      min-width: 150px;
    }
    &__link {
      line-height: 16px;
      color: $color--black;
    }
    .gnav-tout__inner-content {
      @include sub-title--bold;
      text-align: center;
      min-height: 32px;
      display: flex;
      justify-content: center;
      font-weight: 400;
      text-transform: uppercase;
      width: 100%;
      margin-top: 10px;
      line-height: 16px;
      @include breakpoint($bp--medium-up) {
        justify-content: center;
        width: 100%;
      }
      @include breakpoint($bp--large-up) {
        justify-content: flex-start;
        max-width: 110px;
        width: 100%;
        margin-top: 0;
      }
      @include breakpoint($bp--xxlarge-up) {
        width: 100%;
        max-width: 150px;
      }
      .gnav-tout__link a {
        border-bottom: none;
      }
    }
  }
  .gnav-secondary-formatter-wrapper .slick-list {
    margin-#{$ldirection}: 20px;
    width: 100%;
  }
  .slick-slider {
    padding: 0;
  }
  &.slick-sliding .slick-list {
    overflow: visible;
    &:before {
      animation: 3s ease 0s normal forwards 1 fadeout;
    }
  }
  .slick-list {
    &:before {
      content: '';
      opacity: 1;
      position: absolute;
      width: 38px;
      height: 100%;
      top: 0;
      #{$ldirection}: -38px;
      background-color: inherit;
      z-index: 2;
    }
  }
  .gnav-tout__inner {
    a {
      border-bottom: none;
    }
    .gnav-tout__image {
      padding-#{$rdirection}: 0;
    }
  }
  .gnav-tout__inner > * {
    width: 100%;
  }
  .gnav-tout__image {
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-#{$rdirection}: 0;
    @include breakpoint($bp--medium-up) {
      padding-top: 4px;
      margin-bottom: 8px;
    }
    @include breakpoint($bp--xxlarge-up) {
      padding-top: 24px;
      margin-bottom: 8px;
    }
    .mantle-media-asset {
      max-width: 100px;
      max-height: 100px;
      margin: 0 auto;
      @include breakpoint($bp--medium-up) {
        max-width: 110px;
        max-height: 110px;
      }
      @include breakpoint($bp--xxlarge-up) {
        max-width: 150px;
        max-height: 150px;
      }
    }
  }
  .gnav-secondary-formatter__carousel-dots {
    border-bottom: 1px solid $color--very--light--grey;
    .slick-dots {
      bottom: 14px;
    }
    li {
      display: inline-block;
      width: 2px;
      button:before {
        opacity: 0.5;
      }
      &.slick-active {
        button:before {
          opacity: 1;
        }
      }
    }
  }
  .content-block-formatter.dark-theme & {
    .gnav-secondary-formatter__carousel-dots {
      .slick-dots {
        button:before {
          color: $color--white;
        }
      }
    }
  }
  &.dark-theme {
    .gnav-secondary-formatter-wrapper {
      .gnav-tout {
        .gnav-tout__link {
          color: $color--white;
          &:hover {
            color: $color--white;
            opacity: 1;
          }
        }
        &.active {
          border-color: $color--white;
          .gnav-tout__link {
            color: $color--white;
            opacity: 1;
          }
          &:hover {
            .gnav-tout__link {
              color: $color--white;
            }
          }
        }
      }
    }
    &.gnav-secondary--without-images {
      .gnav-secondary-formatter-wrapper {
        .gnav-tout {
          .gnav-tout__link {
            opacity: 0.7;
            &:hover {
              opacity: 1;
            }
          }
          &.active {
            .gnav-tout__link {
              opacity: 1;
            }
          }
        }
      }
      .gnav-secondary-formatter__carousel-dots {
        .slick-dots {
          button:before {
            color: $color--white;
          }
        }
      }
    }
    .slick-list {
      &::before {
        background-color: inherit;
      }
    }
  }
  &.gnav-secondary--without-images {
    height: 97px;
    @include breakpoint($bp--large-up) {
      height: 68px;
    }
    &.gnav-secondary--sticky {
      max-width: initial;

      @include breakpoint($bp--large-up) {
        border-bottom: 1px solid $color--very--light--grey;
      }
    }
    .gnav-secondary-formatter-wrapper {
      background-color: inherit;
      max-width: 1440px;
      margin: 0 auto;
      height: 100%;
      padding: 0;
      justify-content: space-evenly;
    }
    .gnav-tout {
      white-space: nowrap;
      margin: 0 8px;
      padding: 0;
      @include breakpoint($bp--large-up) {
        padding: 0 15px;
        margin: 0;
        max-width: unset;
      }
      &.active {
        padding-bottom: 9px;
        border-bottom: 1px solid $color--black;
        a,
        .gnav-tout__link {
          color: $color--black;
        }
        &:hover {
          a,
          .gnav-tout__link {
            color: $color--black;
          }
        }
      }
      a,
      .gnav-tout__link {
        color: $color-text-grey;
      }
    }
    .gnav-tout__inner,
    .gnav-tout__inner-content {
      height: 100%;
    }
    .gnav-tout__inner-content {
      @include body-text--small-bold;
      max-width: initial;
      .gnav-tout__link {
        margin-top: 10px;
        @include breakpoint($bp--large-up) {
          margin-top: 25px;
        }
      }
    }
  }
}
